<template>
    <Navbar/>
    <Section>
        <Container>
            <Breadcrumbs>
                <template #link>
                    <a class="text-[#000] font-medium" href="javascript:void(0);">
                        {{ __("Yangiliklar") }}
                    </a>
                </template>
            </Breadcrumbs>
            <div class="h-full w-full" v-if="loader">
                <div class="h-[200px] flex items-center justify-center w-full">
                    <span uk-spinner="ratio: 3"></span>
                </div>
            </div>
            <Transition>
                <div v-if="!loader">
                    <div class="my-[28px] py-[28px] border-t border-[#E4E4E4]"
                         v-if="news"
                    >
                        <ul class="uk-grid uk-grid-medium uk-child-width-1-3@l uk-child-width-1-2@m" uk-grid
                            v-if="news.length > 0"
                        >
                            <li v-for="item in news" :key="item.id">
                                <router-link :to="'/news/item/id/' + item.id"
                                             class="flex flex-col hover:no-underline group">
                                    <div class="uk-width-1-3 rounded-[10px] w-full uk-height-medium overflow-hidden">
                                        <img alt=""
                                             class="object-cover rounded-[10px] w-full h-full group-hover:scale-125 transition duration-300"
                                             :src="baseUri+'file/view?id='+item.resource_id">
                                    </div>
                                    <div class="mt-[22px] font-medium leading-[25px] text-[#000]">
                                        {{ item.short_content }}
                                    </div>
                                    <div class="mt-[16px] flex justify-between">
                                        <div class="text-[13px] text-[#A4A4A4] md:text-[15px]">
                                            {{ datetime(item.created_date) }}
                                        </div>
                                        <div class="text-[13px] text-[#A4A4A4] md:text-[15px]">
                                            {{ __("Batafsil") }}
                                            <div class="inline-block ml-2">
                                                <svg height="8.857" viewBox="0 0 13.286 8.857" width="13.286"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <g transform="translate(-5 0.071)">
                                                        <g transform="translate(0 0)">
                                                            <path
                                                                d="M18.077,135.923h0l-3.732-3.714a.714.714,0,0,0-1.008,1.013l2.506,2.494H5.714a.714.714,0,1,0,0,1.429H15.842l-2.506,2.494a.714.714,0,0,0,1.008,1.013l3.732-3.714h0A.715.715,0,0,0,18.077,135.923Z"
                                                                fill="#a4a4a4" transform="translate(0 -132.071)"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </Transition>
        </Container>
    </Section>
    <Footer/>
</template>

<script>
import axios from "axios";
import {api} from "@/config"
import Navbar from "@/components/Layouts/Navbar";
import Footer from "@/components/Layouts/Footer";
import Section from "@/components/Block/Section";
import Container from "@/components/Block/Container";
import Breadcrumbs from "@/components/Block/Breadcrumbs";

export default {
    name: `News`,
    components: {Breadcrumbs, Container, Section, Footer, Navbar},

    data() {
        return {
            news: null,
            loader: true,
            baseUri: api.url,
            params: {
                currPage: 1,
                size: 6,
                order_by_: 'id',
                language: 'uz'
            }
        }
    },
    methods: {
        init() {
            axios.get(api.url + 'blog-and-news?' + this.setParams(this.params))
                .then(res => {
                    this.loader = false;
                    this.news = res.data.data
                }).catch(() => {
                this.loader = false;
            });
        },
        datetime(value) {
            if (value != null) {
                const d = new Date(value);
                let day = this.checkSingleDigit(d.getDate());
                let month = this.checkSingleDigit(d.getMonth() + 1);
                let year = d.getFullYear();
                return `${day}.${month}.${year}`
            }
        },
        checkSingleDigit(digit) {
            return ('0' + digit).slice(-2);
        },
        setParams(value) {
            if (value) {
                return Object.keys(value).map(key => key + '=' + value[key]).join('&');
            }
        },
    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>