<template>
    <div class="flex items-center">
        <router-link class="font-medium text-[#000000] mr-2" to="/">
            {{ __("Bosh sahifa") }}
        </router-link>
        <div class="mr-2">
            <svg height="8.857" viewBox="0 0 13.286 8.857" width="13.286" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-5 0.071)">
                    <g transform="translate(0 0)">
                        <path d="M18.077,135.923h0l-3.732-3.714a.714.714,0,0,0-1.008,1.013l2.506,2.494H5.714a.714.714,0,1,0,0,1.429H15.842l-2.506,2.494a.714.714,0,0,0,1.008,1.013l3.732-3.714h0A.715.715,0,0,0,18.077,135.923Z"
                              fill="#b5b5b5" transform="translate(0 -132.071)"/>
                    </g>
                </g>
            </svg>
        </div>
        <slot name="link"></slot>
    </div>
</template>

<script>
export default {
    name: `Breadcrumbs`
}
</script>

<style scoped>

</style>