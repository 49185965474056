<template>
    <Navbar/>
    <section class="uk-section uk-section-large uk-background-cover heading-section uk-position-relative">
        <div class="uk-container">
            <div class="uk-position-center font-medium text-[30px] text-[#fff] heading-title">
                {{ __("Geoportal / Tizim holati") }}
            </div>
        </div>
    </section>
    <Geoportal/>
    <Footer/>
</template>

<script>
import Navbar from "@/components/Layouts/Navbar";
import Footer from "@/components/Layouts/Footer";
import Geoportal from "@/components/Layouts/Geoportal";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Geoportalindex",
    components: {Navbar, Geoportal, Footer}
}
</script>

<style scoped>
.heading-section {
    background-image: url('../../assets/bg.png') !important;
    object-fit: cover;
    background-color: #003993;
}

.heading-title span {
    font-weight: 900;
}
</style>