<template>
    <Navbar/>
    <section class="uk-section uk-section-large uk-background-cover heading-section uk-position-relative">
        <div class="uk-container uk-container-xsmall">
            <div class="flex items-center justify-center flex-col w-full text-[#fff] heading-title ">
                <p class="uppercase md:text-[28px] font-bold text-center">{{ __("Fazoviy ma_lumotlar geoportali") }}</p>
                <p class="text-center mb-6">{{ __("home_page_content")}}</p>
                <form class="flex w-full relative">
                    <input
                        class="mt-1 text-[#000] pl-5 pr-10 py-3 bg-white border shadow-sm border-slate-300  transition duration-150
                        placeholder:text-[#303030] placeholder-font-light focus:outline-none focus:border-[#003993]
                        focus:ring-[#003993] rounded-[8px] font-light focus:ring-1 w-full"
                        :placeholder="__('So_rov matni')"
                    >
                    <a href="javascript:void(0)" class="w-[23px] -translate-y-1/2 absolute right-4 top-1/2 flex items-center justify-center">
                        <img class="object-cover" src="../../assets/search-icon.svg">
                    </a>
                </form>
            </div>
        </div>
    </section>
    <Informations/>
    <News/>
    <Company/>
    <Footer/>
</template>

<script>
import Navbar from "@/components/Layouts/Navbar";
import Footer from "@/components/Layouts/Footer";
import Informations from "@/components/Layouts/Informations";
import Company from "@/components/Layouts/Company";
import News from "@/components/Block/NewsSlide";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "MainPage",
    components: {Navbar, Informations, Company, News, Footer}
}
</script>

<style scoped>
.heading-section {
    background-image: url('../../assets/19281.png') !important;
    object-fit: cover;
    background-color: #003993;
}

</style>