<template>
    <div class="card-informations-container">
        <a href="javascript:void(0)"
           @click="toOwnPage"
           class="hover:no-underline hover:-translate-y-[18px] transition duration-300 card-statistics bg-[#fff] flex rounded-[35px] flex-col justify-center items-center p-4 min-h-[254px] relative w-full border shadow-lg hover:shadow-2xl shadow-[#E0F1FF] hover:shadow-[#E0F1FF] border-[#E4E4FF]">
            <div>
                <div class="top-4 right-4 absolute">
                    <slot name="informicon"></slot>
                </div>
            </div>
            <div>
                <div class="border-2 border-[#DBE3F0] rounded-full mb-2">
                    <slot name="icon"></slot>
                </div>
            </div>
            <div>
                <div class="text-[#2B2B2B] mb-2 text-[16px] md:text-[18px] font-semibold uppercase">
                    <slot name="menu"></slot>
                </div>
            </div>
            <div>
                <div class="text-[#090909] mb-2 text-center md:text-[16px] font-refular">
                    <slot name="content"></slot>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
    export default {
        name: "InformationsCard",
        props: {
            path: String
        },
        methods: {
            toOwnPage() {
                this.$router.push("/" + this.path)
            }
        }
    }
</script>

<style scoped>
    .card-informations-container {
        position: relative;
    }

    .card-informations-container:last-child::after {
        display: none;
    }

    @media (max-width: 1200px) {
        .card-informations-container::after {
            display: none;
        }
    }
</style>