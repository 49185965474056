<template>
    <Section id="forms" class="bg-[#fff]">
        <Container>
            <div class="uk-grid-medium uk-child-width-1-2@m relative" uk-grid>
                <div v-for="item in organisations" :key="item.id">
                    <Organization :date="datetime(item.created_date)" :link="item.web_site" :menu="item.name_uz"
                                  :number="item.id"
                                  :subtitle="item.address"
                                  :title="item.description"/>
                </div>
            </div>
        </Container>
    </Section>
</template>

<script>
import Container from "@/components/Block/Container";
import Section from "@/components/Block/Section";
import Organization from "@/components/Block/Organization";
import axios from "axios";
import {api} from '@/config';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "OrganizationInput",
    components: {Organization, Section, Container},
    data() {
        return {
            // eslint-disable-next-line vue/no-reserved-keys
            organisations: null,
            url: api.url,
            params: {
                currPage: 1,
                size: 6,
                order_by_: 'id',
                language: 'uz'
            }
        }
    },
    methods: {
        setParams(value) {
            if (value) {
                return Object.keys(value).map(key => key + '=' + value[key]).join('&');
            }
        },
        init() {
            axios.get(this.url + 'organizations?' + this.setParams(this.params)).then(res => {
                this.organisations = res.data.data;
            });
        },
        datetime(value) {
            console.log(value);
            if (value != null) {
                const d = new Date(value);
                let day = this.checkSingleDigit(d.getDate());
                let month = this.checkSingleDigit(d.getMonth() + 1);
                let year = d.getFullYear();
                console.log(`${day}.${month}.${year}`)
                return `${day}.${month}.${year}`
            }
        },
        checkSingleDigit(digit) {
            return ('0' + digit).slice(-2);
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>
</style>