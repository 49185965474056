<template>
    <Section id="organization" class="bg-[#fff]">
        <Container>
            <Grid class=" uk-grid-medium relative">
                <div class="uk-width-1-1@s" v-if="organisations.length > 0">
                    <ul class="uk-nav-parent-icon w-full" uk-nav
                        v-for="item in organisations" :key="item.id"
                    >

                        <h3 style="font-weight: bolder;font-size: 24px;margin: 15px;text-align: center">
                            {{ item.project_full_name }}</h3>

                        <li class="uk-parent mt-1 px-[20px] py-[10px] mb-[13px] bg-white border shadow-lg shadow-[#E0F1FF] border-[#E4E4FF] relative min-h-[38px] md:min-h-[83px] block w-full rounded-[13px] md:text-[18px] focus:ring-1"
                            v-for="(type, index) in g_types" :key="index">
                            <a class="flex items-center text-black font-bold uppercase hover:text-[#003992]" href="#"
                               @click="geo_portal_fields(type.id, item.id)">
                                <div class="flex items-center max-w-[90%]">
                                    <div class="border-2 border-[#DBE3F0] rounded-full flex-shrink-0">
                                        <img class="p-3" :src="type.img_url">
                                    </div>
                                    <div class="ml-2">{{ type.label }}</div>
                                </div>
                            </a>
                            <ul class="uk-nav-sub">
                                <template v-if="g_fields.length > 0">
                                    <li v-for="(field, index) in g_fields" :key="index">
                                        <span class="font-bold text-black"> {{ field.name }} </span> <br/>
                                        <span class="text-black mb-[23px]"> {{field.value}} </span>
                                    </li>
                                </template>
                                <template v-else>
                                    {{ __("Ma_lumotlar topilmadi") }}
                                </template>
                            </ul>
                        </li>

                        <!--                        <li class="uk-parent mt-1 px-[20px] py-[10px] mb-[13px] bg-white border shadow-lg shadow-[#E0F1FF] border-[#E4E4FF] relative min-h-[38px] md:min-h-[83px] block w-full rounded-[13px] md:text-[18px] focus:ring-1">-->
                        <!--                            <a class="flex items-center text-black font-bold uppercase hover:text-[#003992]" href="#">-->
                        <!--                                <div class="flex items-center">-->
                        <!--                                    <div class="border-2 border-[#DBE3F0] rounded-full  flex-shrink-0">-->
                        <!--                                        <img class="p-3" src="../../assets/building.png">-->
                        <!--                                    </div>-->
                        <!--                                    <div class="ml-2">{{ __("Geoportallar") }}</div>-->
                        <!--                                </div>-->
                        <!--                            </a>-->
                        <!--                            <ul class="uk-nav-sub">-->
                        <!--                                <li class="font-bold text-black">{{ __("Loyiha to_liq nomi") }}</li>-->
                        <!--                                <li class="text-black mb-[23px]">“Информатика ва ахборот технологиялари” фани-->
                        <!--                                    ўқитувчиларини тестдан ўтказиш ва сертификатлаштириш тизими-->
                        <!--                                </li>-->
                        <!--                                <li class="font-bold text-black">{{ __("Loyiha qisqa nomi") }}</li>-->
                        <!--                                <li class="text-black mb-[23px]">“Информатика ва ахборот технологиялари” фани-->
                        <!--                                    ўқитувчиларини тестдан ўтказиш ва сертификатлаштириш тизими-->
                        <!--                                </li>-->
                        <!--                                <li class="font-bold text-black">{{ __("Loyihani qisqa tavsifi") }}</li>-->
                        <!--                                <li class="text-black mb-[23px]">“Информатика ва ахборот технологиялари” фани-->
                        <!--                                    ўқитувчиларини тестдан ўтказиш ва сертификатлаштириш тизими-->
                        <!--                                </li>-->
                        <!--                            </ul>-->
                        <!--                        </li>-->

                        <!--                        <li class="uk-parent mt-1 px-[20px] py-[10px] mb-[13px] bg-white border shadow-lg shadow-[#E0F1FF] border-[#E4E4FF] relative min-h-[38px] md:min-h-[83px] block w-full rounded-[13px] md:text-[18px] focus:ring-1">-->
                        <!--                            <a class="items-center text-black font-bold uppercase hover:text-[#003992]" href="#">-->
                        <!--                                <div class="flex items-center">-->
                        <!--                                    <div class="border-2 border-[#DBE3F0] rounded-full">-->
                        <!--                                        <img class="p-3" src="../../assets/server.png">-->
                        <!--                                    </div>-->
                        <!--                                    <p class="ml-2">{{ __("Server quvvati") }}</p>-->
                        <!--                                </div>-->
                        <!--                            </a>-->
                        <!--                            <ul class="uk-nav-sub">-->
                        <!--                                <li class="font-bold text-black">{{ __("server_core") }}</li>-->
                        <!--                                <li class="text-black mb-[23px]">{{ item.server_core }}</li>-->
                        <!--                                <li class="font-bold text-black">{{ __("server_cpu") }}</li>-->
                        <!--                                <li class="text-black mb-[23px]">{{ item.server_cpu }}</li>-->
                        <!--                                <li class="font-bold text-black">{{ __("server_memory") }}</li>-->
                        <!--                                <li class="text-black mb-[23px]">{{ item.server_memory }}</li>-->
                        <!--                                <li class="font-bold text-black">{{ __("server_ram") }}</li>-->
                        <!--                                <li class="text-black mb-[23px]">{{ item.server_ram }}</li>-->
                        <!--                                <li class="font-bold text-black">{{ __("server_thread") }}</li>-->
                        <!--                                <li class="text-black mb-[23px]">{{ item.server_thread }}</li>-->
                        <!--                                <li class="font-bold text-black">{{ __("os") }}</li>-->
                        <!--                                <li class="text-black mb-[23px]">{{ item.os }}</li>-->
                        <!--                                <li class="font-bold text-black">{{ __("db") }}</li>-->
                        <!--                                <li class="text-black mb-[23px]">{{ item.db }}</li>-->


                        <!--                            </ul>-->
                        <!--                        </li>-->

                        <!--                        <li class="uk-parent mt-1 px-[20px] py-[10px] mb-[13px] bg-white border shadow-lg shadow-[#E0F1FF] border-[#E4E4FF] relative min-h-[38px] md:min-h-[83px] block w-full rounded-[13px] md:text-[18px] focus:ring-1">-->
                        <!--                            <a class="items-center text-black font-bold uppercase hover:text-[#003992]" href="#">-->
                        <!--                                <div class="flex items-center">-->
                        <!--                                    <div class="border-2 border-[#DBE3F0] rounded-full">-->
                        <!--                                        <img class="p-3" src="../../assets/status.png">-->
                        <!--                                    </div>-->
                        <!--                                    <p class="ml-2">{{ __("status") }}</p>-->
                        <!--                                </div>-->
                        <!--                            </a>-->
                        <!--                            <ul class="uk-nav-sub">-->
                        <!--                                <li class="font-bold text-black">{{ __("geoportal_statuses_name") }}</li>-->
                        <!--                                <li class="text-black mb-[23px]">{{ item.geoportal_statuses_name }}</li>-->
                        <!--                            </ul>-->
                        <!--                        </li>-->
                    </ul>
                </div>
                <div class="w-full flex items-center justify-center" v-else>
                    <h1 class="md:text-[32px] font-bold">
                        {{ __("Ma_lumotlar topilmadi") }}
                    </h1>
                </div>
            </Grid>
        </Container>
    </Section>
</template>

<script>
    import Grid from "@/components/Block/Grid";
    import Container from "@/components/Block/Container";
    import Section from "@/components/Block/Section";
    import axios from "axios";
    import {api} from '@/config';

    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Geoportal",
        components: {Section, Container, Grid},
        data() {
            return {
                URL: window.location.href,
                organisations: Object,
                g_types: [],
                g_fields: [],
                params: {
                    currPage: 1,
                    size: 4,
                    order_by_: 'id',
                    language: localStorage.getItem('lang') ?? 'uz',
                    // organizations_id: null
                },
                months: [
                    {
                        id: 1,
                        name: 'january'
                    },
                    {
                        id: 2,
                        name: 'february'
                    },
                    {
                        id: 3,
                        name: 'march'
                    },
                    {
                        id: 4,
                        name: 'april'
                    },
                    {
                        id: 5,
                        name: 'may'
                    },
                    {
                        id: 6,
                        name: 'june'
                    },
                    {
                        id: 7,
                        name: 'july'
                    },
                    {
                        id: 8,
                        name: 'august'
                    },
                    {
                        id: 9,
                        name: 'september'
                    },
                    {
                        id: 10,
                        name: 'october'
                    },

                    {
                        id: 11,
                        name: 'november'
                    },

                    {
                        id: 12,
                        name: 'december'
                    },

                ],
            }
        },
        methods: {
            types() {
                axios.get(api.url + 'geoportal-fields/type').then(res => {
                    this.g_types = res.data.data;
                }).catch(function (error) {
                    console.log(error)
                })
            },

            geo_portal_fields(types_id, org_id) {
                this.g_fields = []
                let filter = new Object();
                filter.geoportal_filde_types_id = types_id
                filter.organizations_id = org_id
                axios.get(api.url + 'geoportal-fields' + this.optionPage(filter)).then(res => {
                    this.g_fields = res.data.data;
                }).catch(function (error) {
                    console.log(error)
                })
            },

            getMonth(val) {
                let result = this.months.filter(obj => {
                    return obj.id === val
                });
                // console.log()
                return result[0].name.substr(0, 3)
            },

            optionPage(parObj) {
                let res = '?';
                Object.keys(parObj).forEach(keys => {
                    res += keys + '=' + parObj[keys] + '&'
                })
                // res += "language=" + store.state.language
                return res;
            },

            init() {
                this.params.organizations_id = this.URL.split('?')[1].split('=')[1];
                // let url = new URL(this.URL);
                // this.params.organizations_id = url.searchParams.get('organisation_id');
                axios.get(api.url + 'geo-portals?' + this.setParams(this.params)).then(res => {
                    this.organisations = res.data.data;
                });
            },
            setParams(value) {
                if (value) {
                    return Object.keys(value).map(key => key + '=' + value[key]).join('&');
                }
            },

        },
        mounted() {
            this.init();
            this.types();
        }
    }
</script>

<style scoped>

</style>