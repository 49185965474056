<template>
    <Navbar/>
    <Section>
        <Container>
            <Breadcrumbs>
                <template #link>
                    <router-link to="/news" class="text-[#000] font-medium">
                        {{ __("Yangiliklar") }}
                    </router-link>
                </template>
            </Breadcrumbs>
            <div class="h-full w-full" v-if="loader">
                <div class="h-[200px] flex items-center justify-center w-full">
                    <span uk-spinner="ratio: 3"></span>
                </div>
            </div>
            <Transition>
                <div class="flex flex-wrap" v-if="!loader">
                    <div v-if="single_new" class="w-full md:w-[60%] border-b md:border-b-0 md:border-r border-[#E4E4E4] content-html">
                        <div v-for="item in single_new" :key="item.id" class="py-[16px] md:py-[32px] pr-0 md:pr-[25px]">
                            <div class="text-[#000] font-bold py-[11px] md:py-[22px] md:text-[18px]">
                                {{ item.short_content }}
                            </div>
                            <div class="text-[#A4A4A4] pb-[22px]">
                                {{ datetime(item.created_date) }}
                            </div>
                            <div class="md:text-[17px] " v-html="item.content">
                            </div>
                        </div>
                    </div>
                    <div v-if="news" class="w-full md:w-[40%] flex flex-col uk-child-width-1-1 pl-0 md:pl-6">
                        <div v-for="item in news.filter(item => item.id != (this.params.id))"
                             :key="item.id"
                             class="first:mt-[32px] border-b border-gray-300 last:border-b-0 pb-4 mt-4"
                        >
                            <router-link :to="'/news/item/id/' + item.id" class="flex items-center hover:no-underline"
                                         @click="refreshComponent"
                            >
                                <div class="w-[35%]">
                                    <img alt="" class="w-[100%] rounded-[8px] h-[100%]"
                                         :src="baseUri+'file/view?id='+item.resource_id">
                                </div>
                                <div class="w-[65%] pl-[20px]">
                                    <div class="text-[#000] pb-[10px] text-[16px] leading-none">
                                        {{ item.short_content }}
                                    </div>
                                    <div class="text-[#A4A4A4]">
                                        {{ datetime(item.created_date) }}
                                    </div>
                                </div>
                            </router-link>
                        </div>

                    </div>
                </div>
            </Transition>

        </Container>
    </Section>
    <Footer/>
</template>

<script>

import Navbar from "@/components/Layouts/Navbar";
import Footer from "@/components/Layouts/Footer";
import axios from "axios";
import {api} from "@/config"
import Breadcrumbs from "@/components/Block/Breadcrumbs";
import Container from "@/components/Block/Container";
import Section from "@/components/Block/Section";

export default {
    name: "SingleNews",
    components: {Section, Container, Breadcrumbs, Navbar, Footer},
    data() {
        return {
            single_new: null,
            news: null,
            URL: window.location.href,
            baseUri: api.url,
            loader: true,
            params_rec: {
                currPage: 1,
                size: 6,
                order_by_: 'id',
                language: 'uz',
            },
            params: {
                currPage: 1,
                size: 6,
                order_by_: 'id',
                language: 'uz',
            },

        }
    },
    methods: {
        init() {
            this.loader = false;
            this.params_rec.remove_ids = this.URL.split('id/')[1];
            this.params.id = this.URL.split('id/')[1];
            console.log('params ->', this.params.id)
            axios.get(api.url + 'blog-and-news?' + this.setParams(this.params_rec))
                .then(res => {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.news = res.data.data
                });
            axios.get(api.url + 'blog-and-news?' + this.setParams(this.params))
                .then(res => {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.single_new = res.data.data
                    console.log(this.single_new)
                });
        },
        refreshComponent() {
            let app = this;
            this.loader = true;
            setTimeout(function () {
                app.URL = window.location.href;
                app.init();
                app.loader = false;
            }, 1000)
        },
        datetime(value) {
            if (value != null) {
                const d = new Date(value);
                let day = this.checkSingleDigit(d.getDate());
                let month = this.checkSingleDigit(d.getMonth() + 1);
                let year = d.getFullYear();
                return `${day}.${month}.${year}`
            }
        },
        checkSingleDigit(digit) {
            return ('0' + digit).slice(-2);
        },
        setParams(value) {
            if (value) {
                return Object.keys(value).map(key => key + '=' + value[key]).join('&');
            }
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style>
.content-html img {
    border-radius: 8px !important;
}

.content-html span {
    font-size: 18px !important;
}

@media (max-width: 640px) {
    .content-html span {
        font-size: 14px !important;
    }
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>