<template>
    <div>
        <div class="uk-section">
            <div class="uk-container">
                <form action="">
                    <div class="uk-grid mb-4">
                        <div class="flex justify-start space-x-2">
                            <label for="object_type"></label>
                            <select v-model="selected" @change="onChange($event)" class="mt-1 px-4 bg-[#F5F7FB] border shadow-sm border-[#E1DEF0]
                                                        relative min-h-[38px] md:min-h-[51px] transition duration-150
                                                        placeholder-[#393939] placeholder focus:outline-none focus:border-[#614CA7]
                                                        focus:ring-[#614CA7] block w-full md:w-[247px] rounded-[5px] md:text-[18px] focus:ring-1 uk-cards">
                                <option value="0">Jami</option>
                                <option v-for="(option, index) in geoportals" v-bind:value="option.id" :key="index">
                                    {{ option.project_full_name }}
                                </option>
                            </select>
                            <!--                            <label for="object_type"></label>-->
                            <!--                            <select name="" class="mt-1 px-4 bg-[#F5F7FB] border shadow-sm border-[#E1DEF0]-->
                            <!--                                relative min-h-[38px] md:min-h-[51px] transition duration-150-->
                            <!--                                placeholder-[#393939] placeholder focus:outline-none focus:border-[#614CA7]-->
                            <!--                                focus:ring-[#614CA7] block w-full md:w-[247px] rounded-[5px] md:text-[18px] focus:ring-1 uk-cards">-->
                            <!--                                <option value="">Obyekt turi</option>-->
                            <!--                                <option value="">Noturar</option>-->
                            <!--                                <option value="">Turar</option>-->
                            <!--                            </select>-->
                        </div>
                        <div class="end-placing flex justify-end relative xl:ml-[30%] ">
                            <input class="mt-1 px-4 md:w-[360px] border shadow-sm border-[#E1DEF0]
                            relative min-h-[38px] md:min-h-[51px] transition duration-150
                            placeholder-[#614CA7] placeholder focus:outline-none focus:border-[#614CA7]
                            focus:ring-[#614CA7] block w-full rounded-[5px] md:text-[18px] focus:ring-1 uk-cards"
                                   type="text" placeholder="izlash" v-model="search_value"/>
                            <button type="submit" class="self-center absolute">
                                <img class="mr-[10px] hover:fill-[#614CA7]" src="../../assets/search.svg" alt=""
                                     @click="searcher">
                            </button>
                        </div>
                    </div>
                </form>
                <div>
                    <table class="uk-table uk-table-responsive uk-table-divider">
                        <thead>
                        <tr>
                            <th>Manba nomi</th>
                            <th>Narxi</th>
                            <th>Obyekt nomi</th>
                            <th>Obyekt turi</th>
                            <th>Titul raqami</th>
                            <th>Fayl</th>
                            <th>Nomenklatura</th>
                            <th>Kiritilgan sana</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(source, index) in sources" :key="index">
                            <td> {{source.source_name}}</td>
                            <td>{{number_format (source.price)}}</td>
                            <td>{{source.portal_meta_data_object_name}}</td>
                            <td>{{source.portal_meta_data_object_type}}</td>
                            <td>{{source.titul_number}}</td>
                            <!--                             <td><a href="javascript:void(0)" style="color: blue"><span>{{source.file_name}} </span></a></td> -->
                            <td>
                                <a href="javascript:void(0)" style="color: blue;">
                                    <img style="width: 30% !important" src="../../assets/pin.png"></a>
                            </td>
                            <td>{{source.nomenclatura}}</td>
                            <td>{{ source.created_date ? setDateWithTime (source.created_date): '-'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <ul class="uk-pagination justify-end pt-2 pb-16 space-x-2 align-center justify-items-center transition-style font-semibold uk-visible@s"
                    uk-margin>
                    <li><a class="w-[30px] h-[30px] bg-[#EFEDF6] rounded-[2px] text-[#232323]" href="javascript:void(0)"
                           @click="previousPage"><span
                            uk-pagination-previous></span></a></li>
                    <!--                    <span>{{start}}-{{row_length}} of {{total}}</span>-->
                    <li><a class="w-[30px] h-[30px] bg-[#EFEDF6] rounded-[2px] text-[#232323]" href="javascript:void(0)"
                           @click="nextPage"><span
                            uk-pagination-next></span></a></li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    import axios from "axios";
    import {api} from "@/config"

    // let list = 0;

    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Table",
        data() {
            return {
                selected: 0,
                sources: [],
                geoportals: [],
                total: 0,
                start: 1,
                row_length: 0,
                pagination: {
                    descending: false,
                    page: 1,
                    rowsPerPage: 7,
                    rowsNumber: 0,
                    sortBy: 'id',
                },
                filter: {},
                search_value: '',
                params: {}
            }
        },
        methods: {
            onChange(event) {
                if (event.target.value != 0) {
                    this.filter.geoportals_id = Number(event.target.value)
                } else {
                    delete this.filter.geoportals_id
                }
                this.getSources()
            },

            searcher() {
                if (this.search_value != '') {
                    this.filter.source_name = this.search_value
                } else {
                    delete this.filter.source_name
                }
                this.getSources()
            },

            nextPage() {
                let p_count = Math.round(this.total / this.pagination.rowsPerPage) + 1
                if (this.pagination.page == p_count) {
                    return
                }
                this.pagination.page += 1;
                this.getSource(true);
            },

            previousPage() {
                if (this.pagination.page == 1) {
                    this.pagination.page = 1
                    return
                }
                this.pagination.page -= 1
                this.getSource(false);
            },

            getFilters() {
                let page = this.pagination
                let pagination = {
                    currPage: page.page,
                    size: page.rowsPerPage,
                    descending: page.descending,
                    order_by_: page.sortBy
                }
                let filter = this.filter;
                for (const [key, value] of Object.entries(filter)) {
                    if (value != null && value != undefined) {
                        filter[key] = value
                    }
                }
                for (const [key, value] of Object.entries(pagination)) {
                    if (value != null && value != undefined) {
                        filter[key] = value
                    }
                }
                return filter;
            },

            optionPage(parObj) {
                let res = '?';
                Object.keys(parObj).forEach(keys => {
                    res += keys + '=' + parObj[keys] + '&'
                })
                // res += "language=" + store.state.language
                return res;
            },

            getSource(val) {
                let filter = this.getFilters();
                axios.get(api.url + '/portal-meta-data' + this.optionPage(filter))
                    .then(res => {
                        this.sources = res.data.data;
                        this.total = res.data.total;
                        if (val) {
                            this.row_length += res.data.data.length;
                        } else {
                            this.row_length -= res.data.data.length;
                            if (this.row_length < res.data.data.length) {
                                this.row_length = res.data.data.length
                                this.start = 1
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },


            getSources() {
                let filter = this.getFilters();
                axios.get(api.url + 'portal-meta-data' + this.optionPage(filter))
                    .then(res => {
                        this.sources = res.data.data;
                        this.total = res.data.total;
                        this.row_length += res.data.data.length;
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },

            geoPortals() {
                axios.get(api.url + 'geo-portals' + this.optionPage(this.params)).then(res => {
                    this.geoportals = res.data.data;
                });
            },

            number_format(number, decimals, thousands_sep) {
                return number ? number.toFixed(decimals ? decimals : 2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (thousands_sep ? thousands_sep : ' ')) : "0"
            },

            setDateWithTime(value) {
                let val = value * 1
                let date = new Date(val)
                let day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
                let mes = (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
                let year = date.getFullYear();
                let hour = date.getHours();
                let minutes = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes();
                let second = date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds()
                return day + '.' + mes + '.' + year + '  ' + hour + ':' + minutes + ':' + second
            },
        },
        mounted() {
            this.getSources()
            this.geoPortals()
        }
    }

</script>

<style scoped>
    .uk-table th {
        padding: 25px 0px;
        text-align: left;
        vertical-align: bottom;
        font-size: 16px;
        font-weight: 700;
        color: #614CA7 !important;
        text-transform: none !important;
    }

    .uk-table td {
        padding: 25px 12px;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 500;
        color: #393939;
        text-transform: none;
    }

    .end-placing {
        right: 0 !important;
    }

    .placeholder {
        font-size: 16px;
        color: #393939 !important;
        font-weight: 500;
    }

</style>