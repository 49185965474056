<template>
    <Section id="informations">
        <Container>
            <Heading>
                <template #title>
                    {{__("ma_lumotlar")}}
                </template>
            </Heading>
            <Grid class="uk-grid-medium uk-child-width-1-3@l uk-grid-match uk-child-width-1-2@m">
                <InformationsCard :path="'basic-concept'">
                    <template #informicon>
                        <img src="../../assets/inform_icon.png">
                    </template>
                    <template #content>
                        <!--                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam-->
                    </template>
                    <template #menu>
                        Asosiy tushunchalar
                    </template>
                    <template #icon>
                        <img alt="building" class="px-4 py-3" src="../../assets/document.png">
                    </template>
                </InformationsCard>
                <InformationsCard :path="'organisations'">
                    <template #informicon>
                        <img src="../../assets/inform_icon.png">
                    </template>
                    <template #content>
                        <!--                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam-->
                    </template>
                    <template #menu>
                        geoportallar
                    </template>
                    <template #icon>
                        <img alt="building" class="p-3" src="../../assets/building.png">
                    </template>
                </InformationsCard>

                <InformationsCard :path="'geo-portal-requirements'">
                    <template #informicon>
                        <img src="../../assets/inform_icon.png">
                    </template>
                    <template #content>
                        <!--                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam-->
                    </template>
                    <template #menu>
                        Geoportallarga talablar
                    </template>
                    <template #icon>
                        <img alt="building" class="p-3" src="../../assets/building.png">
                    </template>
                </InformationsCard>

            </Grid>
        </Container>
    </Section>
</template>

<script>
    import InformationsCard from "@/components/Block/InformationsCard";
    import Grid from "@/components/Block/Grid";
    import Heading from "@/components/Layouts/Heading";
    import Container from "@/components/Block/Container";
    import Section from "@/components/Block/Section";

    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Informations",
        components: {Section, Container, Heading, Grid, InformationsCard}
    }
</script>

<style scoped>
    #informations {
        position: relative;
        overflow: hidden;
    }

    #informations::before {
        content: '';
        display: block;
        left: 0;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #F5F7FB;
    }

</style>