<template>
    <Navbar/>
    <Section>
        <Container>
            <Breadcrumbs>
                <template #link>
                    <a class="text-[#000] font-medium" href="javascript:void(0);">
                        {{ __("metadata") }}
                    </a>
                </template>
            </Breadcrumbs>
<!--            <div class="h-full w-full" v-if="loader">-->
<!--                <Table />-->
<!--            </div>-->
            <Transition>
                <div v-if="!loader">
                    <Table />
                </div>
            </Transition>
        </Container>
    </Section>
    <Footer/>
</template>

<script>
    import Navbar from "@/components/Layouts/Navbar";
    import Container from "@/components/Block/Container";
    import Breadcrumbs from "@/components/Block/Breadcrumbs";
    import Section from "@/components/Block/Section";
    import Footer from "@/components/Layouts/Footer";
    import Table from "@/components/Home/Table";
    import axios from "axios";
    import {api} from "@/config";
    export default {
        name: "PortalMetaData",
        components: {Table, Footer, Section, Container, Breadcrumbs, Navbar},
        data() {
            return {
                loader: false,
                meta_data: [],
                url: api.url,
                params: {
                    currPage: 1,
                    size: 6,
                    order_by_: 'id',
                    language: 'uz'
                },
                pagination: {
                    descending: false,
                    page: 1,
                    rowsPerPage: 7,
                    rowsNumber: 0,
                    sortBy: 'id',
                },
                filter: {
                    source_name: '',
                }
            }
        },
        methods: {

        },
        mounted() {
            // this.init()
        }
    }
</script>

<style scoped>

</style>