<template>
    <div class="uk-grid" uk-grid>
        <slot></slot>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Grid"
}
</script>

<style scoped>

</style>