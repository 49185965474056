<template>
    <Navbar/>
    <Section>
        <Container>
            <Breadcrumbs>
                <template #link>
                    <a class="text-[#000] font-medium" href="javascript:void(0);">
                        {{ __("geo_portal_requirements") }}
                    </a>
                </template>
            </Breadcrumbs>
            <Transition>
                <div class="flex flex-wrap" v-if="!loader">
                    <div
                            class="w-full md:w-[60%] border-b md:border-b-0 md:border-r border-[#E4E4E4] content-html">
                        <div class="py-[16px] md:py-[32px] pr-0 md:pr-[25px]">
                            <div class="text-[#000] font-bold py-[11px] md:py-[22px] md:text-[18px]">
                                {{ current_portal_requirement.short_content }}
                            </div>
                            <div class="md:text-[17px] " v-html="current_portal_requirement.content">
                            </div>
                        </div>
                    </div>

                    <div v-if="portal_requirement" class="w-full md:w-[40%] flex flex-col uk-child-width-1-1 pl-0 md:pl-6">
                        <div v-for="(item, index) in portal_requirement" :key="item.id"
                             class="first:mt-[32px] border-b border-gray-300 last:border-b-0 pb-4 mt-4"
                        >
                            <a class="flex items-center hover:no-underline" @click="refreshComponent(item)">
                                <div class="w-[65%] pl-[20px]">
                                    <div class="text-[#000] pb-[10px] text-[16px] leading-none">
                                        {{ (index + 1) + '. ' + item.short_content }}
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </Transition>
        </Container>

    </Section>
    <Footer/>
</template>

<script>
    import Navbar from "@/components/Layouts/Navbar";
    import Section from "@/components/Block/Section";
    import Footer from "@/components/Layouts/Footer";
    import Container from "@/components/Block/Container";
    import Breadcrumbs from "@/components/Block/Breadcrumbs";
    import {api} from "@/config";
    import axios from "axios";

    export default {
        name: "GeoPortalRequirements",
        components: {Breadcrumbs, Container, Footer, Section, Navbar},
        data() {
            return {
                baseUri: api.url,
                portal_requirement: [],
                current_portal_requirement: [],
                loader: false,
                params: {
                    currPage: 1,
                    size: 6,
                    order_by_: 'id',
                    language: 'uz',
                    content_types_id: 2
                },
            }
        },
        methods: {
            init() {
                this.loader = true;
                axios.get(api.url + 'basic-concepts?' + this.setParams(this.params))
                    .then(res => {
                        // eslint-disable-next-line vue/no-mutating-props
                        this.portal_requirement = res.data.data
                        this.current_portal_requirement = res.data.data[0]
                        this.loader = false;
                    }).catch(err => {
                    this.loader = false;
                    console.log(err)
                });
            },

            setParams(value) {
                if (value) {
                    return Object.keys(value).map(key => key + '=' + value[key]).join('&');
                }
            },

            refreshComponent(item) {
                this.current_portal_requirement = item
            },
        },
        mounted() {
            this.init()
        }
    }
</script>

<style scoped>

</style>