<template>
    <Section id="news">
        <Container>
            <Heading>
                <template #title>
                    {{ __("Yangiliklar") }}
                </template>
            </Heading>
            <div class="px-3" uk-slider >
                <div class="uk-position-relative">
                    <div v-if="news">
                        <ul class="uk-slider-items uk-grid-match uk-child-width-1-2@s uk-child-width-1-1 uk-child-width-1-3@m uk-grid uk-grid-medium"
                            uk-grid
                            :class="{'pb-[30px]' : getLength(news) < 3}"
                        >
                            <li v-for="item in news" :key="item.id" class="mb-2">
                                <NewsCard :id="(item.id)">
                                    <template #content>
                                        <a class="no-underline hover:no-underline hover:text-red"
                                           href="javascript:void(0)"
                                        >
                                            {{ item.short_content }}
                                        </a>
                                    </template>
                                    <template #datetime>
                                        {{ datetime(item.created_date) }}
                                    </template>
                                    <template #sign>
                                        <a href="javascript:void(0);" uk-icon="icon:arrow-right"></a>
                                    </template>
                                    <template #img>
                                        <img alt="earth" class="w-full h-[250px] object-cover rounded-t-[4px]"
                                             :src="baseUri+'file/view?id='+item.resource_id"
                                             uk-img="target: #news"
                                        >
                                    </template>
                                </NewsCard>
                            </li>

                        </ul>
                    </div>


                    <div class="uk-visible@s dot-nav-style">
                        <a class="uk-position-center-left-out uk-position-small" href="#" uk-slidenav-previous
                           uk-slider-item="previous"></a>
                        <a class="uk-position-center-right-out uk-position-small" href="#" uk-slidenav-next
                           uk-slider-item="next"></a>
                    </div>
                </div>

                <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
            </div>
        </Container>
    </Section>
</template>

<script>
import NewsCard from "@/components/Block/NewsCard";
import Heading from "@/components/Layouts/Heading";
import Container from "@/components/Block/Container";
import Section from "@/components/Block/Section";
import axios from "axios";
import {api} from "@/config";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "News",
    components: {Section, Container, Heading, NewsCard},
    data() {
        return {
            news: null,
            baseUri: api.url,
            params: {
                currPage: 1,
                size: 6,
                order_by_: 'id',
                language: 'uz'
            },
        }
    },
    methods: {
        init() {
            axios.get(api.url + 'blog-and-news?' + this.setParams(this.params))
                .then(res => {
                    this.news = res.data.data
                });
        },
        datetime(value) {
            if (value != null) {
                const d = new Date(value);
                let day = this.checkSingleDigit(d.getDate());
                let month = this.checkSingleDigit(d.getMonth() + 1);
                let year = d.getFullYear();
                return `${day}.${month}.${year}`
            }
        },
        checkSingleDigit(digit) {
            return ('0' + digit).slice(-2);
        },
        setParams(value) {
            if (value) {
                return Object.keys(value).map(key => key + '=' + value[key]).join('&');
            }
        },
        getLength(value){
            console.log(Object(value).length)
            return Object(value).length;
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style>
.dot-nav-style a {
    color: #003992;
    padding: 10px 15px;
    transition: all .2s ease;
    border-radius: 9px;
}

.dot-nav-style a:hover {
    background-color: #003992;
    color: white;
}

.uk-dotnav > * > * {
    display: block;
    box-sizing: border-box;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: 0 0;
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
    border: 1px solid #003992 !important;
    transition: .2s ease-in-out;
}

.uk-dotnav > .uk-active > * {
    background-color: #003992 !important;
    border-color: transparent;
}
</style>