<template>
    <div class="flex items-center justify-center w-full text-center mb-6 mt-4">
    <span class="heading mb-4 font-bold text-[#003993] uppercase md:text-[25px] text-[18px] mx-auto relative">
      <slot name="title"></slot>
    </span>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Heading"
}
</script>

<style scoped>
.heading {
}

</style>