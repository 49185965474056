<template>
    <div>
        <div class="relative w-full header-top uk-box-shadow-medium z-10">
            <marquee style="margin-top:2px;font-size: 18px;font-weight: bolder;" scrollamount="7">

                Sayt test holatida ishlayabdi
            </marquee>
            <hr/>
            <div class="uk-container">
                <nav class="uk-navbar" uk-navbar>
                    <div class="uk-navbar-left">
                        <ul class="uk-navbar-nav">
                            <li>
                                <router-link class="flex items-center hover:no-underline" to="/">
                                    <img style="height: 35px" alt="emblem" class="img-style" src="../../assets/FMMI.png" uk-img />
<!--                                    <img alt="emblem" class="img-style" src="../../assets/FMMI.svg" uk-img />-->
<!--                                    <div class="uppercase font-semibold ml-4 font-[25px] text-[#003992]">-->
<!--                                        FMMI-->
<!--                                    </div>-->
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="uk-navbar-center uk-visible@m ml-30">
                        <ul class="uk-navbar-nav">
                            <li>
                                <router-link :active-class="'font-bold'"
                                             class="text-[#303030] font-medium"
                                             to="/"
                                >
                                    {{ __("Qidiruv tizimi") }}
                                </router-link>
                            </li>
                            <li>
                                <router-link :active-class="'font-bold'" class="text-[#303030] font-medium"
                                             to="/news"
                                >
                                    {{ __("Yangiliklar") }}
                                </router-link>
                            </li>
                            <li>
                                <router-link :active-class="'font-bold'" class="text-[#303030] font-medium"
                                             to="/organisations"
                                >
                                    {{ __("Geoportallar") }}
                                </router-link>
                            </li>
                            <li>
                                <router-link :active-class="'font-bold'" class="text-[#303030] font-medium"
                                             to="/metadata"
                                >
                                    {{ __("metadata") }}
                                </router-link>
                            </li>
                            <li>
                                <a href="#footer"
                                   uk-scroll
                                   class="text-[#303030] font-medium"
                                >
                                    {{ __("Aloqa") }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="uk-navbar-right ">
                        <ul class="uk-navbar-nav uk-visible@m">
                            <li>
                                <a class="text-reset text-[#003993] leading-6"
                                   href="/admin/">{{ __("kirish") }}</a>
                            </li>
                            <li class="hidden">
                                <a href="javascript:void(0)"><img class="w-[34px] h-[26px]"
                                                                  src="../../assets/close.png"></a>
                            </li>
                            <li class="hidden">
                                <a href="javascript:void(0)">
                                    <img class="border-2 border-[#DBE3F0] rounded-full p-2" src="../../assets/user.png">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span
                                        class="flex items-center justify-center text-reset rounded-full bg-[#003993] text-white w-[37px] h-[37px] p-2">
                                        {{ currentLang === 'ru' ? 'Ru' : '' }}
                                        {{ currentLang === 'uz' ? 'Uz' : '' }}
                                        {{ currentLang === 'en' ? 'En' : '' }}
                                    </span>
                                    <div uk-dropdown="mode: click;pos: bottom-right"
                                         class="rounded-[5px] bg-[#fff] uk-box-shadow-medium"
                                         style="padding: 10px!important; min-width: 47px!important;">
                                        <ul class=" uk-navbar-dropdown-nav">
                                            <li v-for="(item,index) in languages.filter(item => item !== currentLang)" :key="index" class="first:mt-0 mt-2">
                                                <a href="#"
                                                   class="hover:no-underline  navbar-lang"
                                                   @click="setLang(item)"
                                                >
                                                    {{ item === 'ru' ? 'Ru' : '' }}
                                                    {{ item === 'uz' ? 'Uz' : '' }}
                                                    {{ item === 'en' ? 'En' : '' }}
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div class="uk-navbar-nav uk-hidden@m">
                            <a class="uk-navbar-toggle text-[#003994] hover:text-[#003992]"
                               uk-toggle="target: #offcanvas-flip"
                               uk-navbar-toggle-icon href="#">
                            </a>
                            <div id="offcanvas-flip" uk-offcanvas="flip: true; overlay: true">
                                <div class="uk-offcanvas-bar bg-[#fff]">

                                    <div>
                                        <button class="uk-offcanvas-close color-blue" type="button" uk-close></button>
                                    </div>

                                    <div class="flex flex-col uk-margin-medium-top">
                                        <div>
                                            <router-link :active-class="'font-bold'"
                                                         class="text-[#303030] color-blue font-medium"
                                                         to="/"
                                            >
                                                {{ __("Qidiruv tizimi") }}
                                            </router-link>
                                        </div>
                                        <div>
                                            <router-link :active-class="'font-bold'"
                                                         class="color-blue text-[#303030] font-medium"
                                                         to="/news"
                                            >
                                                {{ __("Yangiliklar") }}
                                            </router-link>
                                        </div>
                                        <div>
                                            <router-link :active-class="'font-bold'"
                                                         class="color-blue text-[#303030] font-medium"
                                                         to="/organisations"
                                            >
                                                {{ __("Geoportallar") }}
                                            </router-link>
                                        </div>
                                        <div>
                                            <router-link :active-class="'font-bold'"
                                                         class="color-blue text-[#303030] font-medium"
                                                         to="/metadata"
                                            >
                                                {{ __("metadata") }}
                                            </router-link>
                                        </div>
                                        <div>
                                            <a href="#footer"
                                               uk-scroll
                                               class="text-[#303030] color-blue font-medium"
                                            >
                                                {{ __("Aloqa") }}
                                            </a>
                                        </div>
                                    </div>

                                    <div class="flex flex-row justify-between items-center mt-4">
                                        <div>
                                            <a class="text-reset text-[#003993] color-blue leading-6"
                                               href="/admin/">{{ __("kirish") }}</a>
                                        </div>
                                        <div>
                                            <a href="javascript:void(0)" class="hover:no-underline">
                                                <span
                                                    class="flex items-center justify-center text-reset rounded-full bg-[#003993] text-white w-[37px] h-[37px] p-2">
                                                    {{ currentLang === 'ru' ? 'Ru' : '' }}
                                                    {{ currentLang === 'uz' ? 'Uz' : '' }}
                                                    {{ currentLang === 'en' ? 'En' : '' }}
                                                </span>
                                                <div uk-dropdown="mode: click;pos: bottom-right"
                                                     class="rounded-[5px] bg-[#fff] uk-box-shadow-medium"
                                                     style="padding: 10px!important; min-width: 47px!important;">
                                                    <ul class=" uk-navbar-dropdown-nav">
                                                        <li v-for="(item,index) in languages.filter(item => item !== currentLang)" :key="index" class="first:mt-0 mt-2">
                                                            <a href="#"
                                                               class="hover:no-underline  navbar-lang"
                                                               @click="setLang(item)"
                                                            >
                                                                {{ item === 'ru' ? 'Ru' : '' }}
                                                                {{ item === 'uz' ? 'Uz' : '' }}
                                                                {{ item === 'en' ? 'En' : '' }}
                                                            </a>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Navbar",
    data(){
        return {
            languages: ['uz', 'ru', 'en'],
            currentLang: localStorage.getItem('lang')
        }
    },
    methods: {
        setLang(value){
            localStorage.setItem('lang', value);
            this.currentLang = localStorage.getItem('lang');
        }
    }
}
</script>

<style scoped>
.header-top {

}
.navbar-lang{
    color: #003993!important;
}
.navbar-lang:hover{
    color: #000!important;
}

.navbar-style li a:hover {
    color: #003993;
    text-decoration: none;
}

.text-reset {
    text-transform: none !important;

}

.text-\[#003993\] {
    color: #003993 !important;
}

.uk-navbar-nav > li:hover > a, .uk-navbar-nav > li > a[aria-expanded=true] {
    color: #303030;
}

.uk-navbar-nav > li > a {
    color: #003993 !important;
    text-transform: none !important;
    font-size: 16px;
}

.color-blue {
    color: #003992 !important;
}
</style>