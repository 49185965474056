<template>
    <router-link :to="'/news/item/id/' + id"
                 class="card group hover:no-underline shadow-lg hover:shadow-xl shadow-[#E0F1FF] hover:shadow-[#E0F1FF] transition duration-200 rounded-[4px] relative flex flex-col">
        <div class="w-full">
            <div class="card__image">
                <slot name="img"></slot>
            </div>
        </div>
        <div class="flex flex-col px-4 pb-4 w-full h-full justify-between">
            <div class="pb-8">
                <div class="card__content group-hover:text-[#00489F] font-semibold uppercase mt-6 pl-4">
                    <slot name="content"></slot>
                </div>
            </div>
            <div class="w-full">
                <div class="card__datetime flex justify-between w-full font-medium">
                    <slot class="datetime" name="datetime"></slot>
                    <slot class="sign" name="sign"></slot>
                </div>
            </div>
        </div>
    </router-link>

</template>

<script>
export default {
    name: "NewsCard",
    props: {
        id: Number
    }
}
</script>

<style scoped>
.card {
    justify-items: center;
    align-items: center;
    background-color: #fff;
}

.card__content {
    color: #3B4256;
    font-size: 16px;
    font-weight: bold;
    position: relative;
}

.card__content::after {
    content: '';
    position: absolute;
    height: 31px;
    width: 2px;
    border-radius: 9px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #003993;
    display: block;
    left: 0;
}

.card__content:hover {
    color: #00489F;
}

.card__tag {
    background-color: #003993;
    color: #ffffff;
    border-radius: 9px;
    font-size: 17px;
    padding: 9px 20px;
    display: inline-block;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.card__image {
    border-radius: 5px;
    position: relative;
    width: 100%;
    max-height: 250px;
}

.card__datetime {
    color: #A4A4A4;
    margin-top: 1rem;
    font-size: 14px;
}

</style>