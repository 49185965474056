<template>
    <div>
        <router-link :to="'/services/?organisation_id=' + number" class="block hover:no-underline rounded-[13px] shadow-lg shadow-[#ccdfee] px-[30px] py-[28px]">
            <div
                class="flex justify-between flex-wrap items-center w-full text-[#003993] mb-[30px] border-b pb-[12px] border-[#E4E4FF]">
                <div class="">
                    <div class="uppercase font-bold">{{ menu }}</div>
                </div>
                <div class="ml-auto flex">
                    <div class="font-bold">
                        <span class="text-black font-normal">{{__("Reg. №")}}:</span> {{ number }}
                    </div>
                    <div class="ml-2 font-bold">
                        <span class="text-black font-normal">{{__("Sana")}}: </span> {{ date }}
                    </div>
                </div>
            </div>
            <div class="font-normal text-[#0A0A0A] mb-[38px]">
                {{ title }}
            </div>
            <div class="font-bold text-black mb-[19px]">
                {{ subtitle }}
            </div>
            <div class="text-[#003993] font-bold mb-[20px]">
                <a class="underline-none hover:no-underline" :href="link" target="_blank">{{ link }}</a>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Organization",
    props: {
        menu: String,
        number: String,
        date: String,
        title: String,
        subtitle: String,
        link: String,
    }
}
</script>

<style scoped>

</style>