<template>
    <footer id="footer" class="uk-section uk-padding-remove uk-background-cover flex flex-col footer-style">
        <div class="uk-container">
            <div class="w-full py-10">
                <Grid class="uk-grid-medium">
                    <div class="w-full md:w-[50%]">
                        <div class="flex flex-row justify-center md:justify-start">
                            <div class="log_style border-1">
                                <img src="../../assets/emblem.png">
                            </div>
                            <div class="log_style flex-wrap uk-text-bold uppercase self-center ml-4 text-white" style="white-space: pre-line">
                                {{ __("Fazoviy Ma_lumotlar Milliy Infratuzilamsi") }}
                            </div>
                        </div>
                    </div>
                    <div class="md:w-[50%] w-full">
                        <div class="grid grid-cols-3 flex-wrap  justify-items-center text-center font-medium text-white link-style">
                            <li>
                                <router-link :active-class="'font-bold'"
                                             class="no-underline hover:no-underline hover:font-bold hover:text-white hover:border-b hover:border-white"
                                             to="/"
                                >
                                    {{ __("Qidiruv tizimi") }}
                                </router-link>
                            </li>
                            <li>
                                <router-link :active-class="'font-bold'"
                                             class="no-underline hover:no-underline hover:font-bold hover:text-white hover:border-b hover:border-white"
                                             to="/news"
                                >
                                    {{ __("Yangiliklar") }}
                                </router-link>
                            </li>
                            <li>
                                <router-link :active-class="'font-bold'"
                                             class="no-underline hover:no-underline hover:font-bold hover:text-white hover:border-b hover:border-white"
                                             to="/organisations"
                                >
                                    {{ __("Geoportallar") }}
                                </router-link>
                            </li>
                        </div>
                    </div>
                    <div class="w-[100%]">
                        <div class="flex items-center">
                            <img class="w-[17px] h-[23px]" src="../../assets/location.png">
                            <div class="md:w-[336px] font-light ml-2 text-white">
                                {{
                                    __("address")
                                }}
                            </div>
                        </div>
                    </div>

                    <div class="w-full md:w-[40%]">
                        <div class="font-light text-white"
                             v-html="__('Tizim «Geoinfocom» davlat unitar korxonasi tomonidan ishlab chiqilgan.')">
                        </div>
                    </div>
                    <div class="w-full md:w-[60%] flex justify-end items-center">
                        <div class="text-white font-light mr-3">
                            {{ __("Biz ijtimoiy tarmoqlarda:") }}
                        </div>
                        <div>
                            <a href="javascript:void(0);" class="object-cover">
                                <div
                                    class="rounded-full bg-white w-[35px] h-[35px] mr-2 p-2 flex items-center justify-center">
                                    <img alt="" class="" src="../../assets/facebook.png">
                                </div>
                            </a>
                        </div>
                        <div>
                            <a href="javascript:void(0);" class="object-cover">
                                <div
                                    class="rounded-full bg-white w-[35px] h-[35px] mr-2 p-2 flex items-center justify-center">
                                    <img alt="" class="" src="../../assets/twitter.png">
                                </div>
                            </a>
                        </div>
                        <div>
                            <a href="javascript:void(0);" class="object-cover">
                                <div
                                    class="rounded-full bg-white w-[35px] h-[35px] mr-2 p-2 flex items-center justify-center">
                                    <img alt="" class="" src="../../assets/telegram.png">
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="w-[100%] ml-6 border-t border-color-[#707070]"></div>
                    <div class="w-[100%] text-white font-regular">
                        {{ __("copyright") }}
                    </div>
                </Grid>
            </div>
        </div>
    </footer>
</template>

<script>
import Grid from "@/components/Block/Grid";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Footer",
    components: {Grid}
}
</script>

<style scoped>
.footer-middle {
    content: "";
    right: 0;
    top: 0;
    margin-top: auto;
    margin-right: 15px;
    height: 12px;
    display: block;
    background-color: #FE634E;
    z-index: -1;
}

.footer-middle-style {
    content: "";
    right: 0;
    width: 50%;
    top: 0;
    margin-top: auto;
    margin-right: 15px;
    display: block;
    background-color: ;
    z-index: -1;
}

.footer-style {
    background-color: #003993;
    background-image: url('../../assets/footer_bg.png') !important;
    object-fit: cover !important;
}


</style>