import {createRouter, createWebHashHistory} from 'vue-router'
import Home from '../components/Home/Home';
import Organisations from "@/components/Home/Organisations";
import Services from "@/components/Home/Services";
import News from "@/components/Home/News";
import SingleNews from "@/components/Home/SingleNews";
import PortalMetaData from "@/components/Home/PortalMetaData";
import BasicConcepts from "@/components/Home/BasicConcepts";
import GeoPortalRequirements from "@/components/Home/GeoPortalRequirements";

const routes = [
    {
        path: '/', name: 'home', component: Home
    },
    {
        path: '/organisations', name: 'organisations', // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Organisations
    },
    {
        path: '/services', name: 'services', // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Services
    },
    {
        path: '/metadata', name: "metadata", // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: PortalMetaData
    },
    {
        path: '/news', name: "news",
        component: News
    },
    {
        path: '/basic-concept', name: "basic_concept",
        component: BasicConcepts
    },
    {
        path: '/geo-portal-requirements', name: "geo_portal_requirements",
        component: GeoPortalRequirements
    },
    {
        path: '/news/item/id/:id', name: "single_news",
        component: SingleNews
    },

]

const router = createRouter({
    history: createWebHashHistory(), // history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
