<template>
    <Section id="forms" class="bg-[#F5F7FB] uk-section-small">
        <Container>
            <Grid class="uk-grid-medium items-center uk-visible@m uk-child-width-1-5 relative justify-start space-x-0">
                <div>
                    <a href="javascript:void(0);">
                        <img class="mx-auto" src="../../assets/kadastral_agency.png">
                    </a>
                </div>
                <div>
                    <a href="javascript:void(0);">
                        <img class="mx-auto" src="../../assets/geoinfocom.png">
                    </a>
                </div>
                <div class="">
                    <a href="javascript:void(0);">
                        <img class="mx-auto" src="../../assets/lex-uz.png">
                    </a>
                </div>
                <div>
                    <a href="javascript:void(0);">
                        <img class="mx-auto" src="../../assets/mygov.png">
                    </a>
                </div>
                <div>
                    <a href="javascript:void(0);">
                         <img class="mx-auto" src="../../assets/soliq.png">
                    </a>
                </div>
            </Grid>
        </Container>
        <Grid class="uk-grid-medium uk-hidden@m  items-center">
            <div class="w-full overflow-hidden" uk-slider="autoplay: true; autoplay-interval: 3000;">
                <ul class="uk-slider-items  items-center uk-child-width-1-2">
                    <li>
                        <a href="javascript:void(0);">
                            <img class="mx-auto" src="../../assets/kadastral_agency.png">
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">
                            <img class="mx-auto" src="../../assets/geoinfocom.png">
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">
                            <img class="mx-auto" src="../../assets/lex-uz.png">
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">
                            <img class="mx-auto" src="../../assets/mygov.png">
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">
                            <img class="mx-auto" src="../../assets/soliq.png">
                        </a>
                    </li>
                </ul>


            </div>
        </Grid>
    </Section>
</template>

<script>
import Grid from "@/components/Block/Grid";
import Container from "@/components/Block/Container";
import Section from "@/components/Block/Section";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "OrganizationInput",
    components: {Section, Container, Grid}
}
</script>

<style scoped>

</style>