<template>
    <router-view/>
</template>
<script>

import UIkit from 'uikit/dist/js/uikit.min';
import Icons from 'uikit/dist/js/uikit-icons.min';

UIkit.use(Icons);

export default {
    name: 'App',
    components: {},
    methods: {
        init() {
            if (!localStorage.getItem('lang'))
                localStorage.setItem('lang', 'uz')
        }
    },
    mounted() {
        this.init();
    }
}


</script>
<style>
@import url('~uikit/dist/css/uikit.min.css');
@import url('./style/style.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
    font-family: 'Montserrat', sans-serif!important;
}
</style>
